// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutme-js": () => import("/opt/build/repo/src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-cake-js": () => import("/opt/build/repo/src/pages/cake.js" /* webpackChunkName: "component---src-pages-cake-js" */),
  "component---src-pages-goat-js": () => import("/opt/build/repo/src/pages/goat.js" /* webpackChunkName: "component---src-pages-goat-js" */),
  "component---src-pages-hbd-js": () => import("/opt/build/repo/src/pages/hbd.js" /* webpackChunkName: "component---src-pages-hbd-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

